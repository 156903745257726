.footerlinks-1{
    list-style-type: none;
    padding: 0px;
}
.footerlinks-1 em{
    margin-inline-end: 10px;
}
.footerlinks-1 li{
    margin-bottom: 3px;
}
.footerlinks-1 a{
    color: #231f20;
    text-decoration: none;
}
.footerlinks-1 li:hover{
    color: #b5a03a;
    cursor: default;
}
.footerlinks-1 a:hover{
    color: #b5a03a;
}

.contrastImg {
    filter: grayscale(1);
}
.contrastImg::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.50;
    z-index: -1;
  }


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}



  @media only screen and (max-width: 600px) {
    img {
      /* Your custom styles for img tags on small screens */
      /* For example: */
      width: 100%;
    }
  
    iframe {
      /* Your custom styles for iframe tags on small screens */
      /* For example: */
      width: 100%;
      height: auto;
    }
  }
  @media print {
    .ignore-print {
      display: none;
    }
  }